import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { extendAnalyticsData, minSearchLength, reloadCasinoFilters, resetCasinoFilters, selectCasinoFilters, selectCasinoFiltersLoading, selectCasinoFiltersSearchValue, selectCasinoFiltersSearchVisible, sendAnalyticsData, setCasinoFiltersResultVisible, setCasinoFiltersSearchValue, setCasinoFiltersSearchVisible, setInitialCasinoFilters, useSwitchValue } from '@mwl/core-lib';
import { getTestProps } from '@mwl/marker-tree';
import ArrowIcon from '@public/assets/common/icons/arrow.svg';
import FilterIcon from '@public/assets/common/icons/filter.svg';
import { useTypedDispatch, useTypedSelector } from '@/hooks';
import { casinoPageObject } from '@/utils';
import { Button } from '../Button/Button';
import { Dropdown } from '../Dropdown/Dropdown';
import { FormFieldSearch } from '../FormFieldSearch/FormFieldSearch';
import { CasinoFilterList } from './components/CasinoFilterList/CasinoFilterList';
import { featureAnalytics, genreAnalytics, hideFiltersAnalytics, providerAnalytics, SEARCH_CLEAR, SEARCH_CLICK, SEARCH_REQUEST, showFiltersAnalytics } from './CasinoFilters.analytics';
import type { CasinoFiltersProps } from './CasinoFilters.types';
import { useCasinoFilters, useDropdown } from './hooks';
import styles from './CasinoFilters.module.scss';
const CasinoFilters: FC<CasinoFiltersProps> = ({
  renderFilters,
  productType,
  ...props
}) => {
  const {
    query,
    pathname
  } = useRouter();
  const {
    t
  } = useTranslation();
  const dispatch = useTypedDispatch();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const {
    value: showFilters,
    toggle: toggleShowFilters
  } = useSwitchValue(false);
  const featureProps = useDropdown(false);
  const genreProps = useDropdown(false);
  const providerProps = useDropdown(false);
  useCasinoFilters({
    productType
  });
  const {
    feature,
    genre,
    provider
  } = useTypedSelector(selectCasinoFilters);
  const isLoading = useTypedSelector(selectCasinoFiltersLoading);
  const showSearch = useTypedSelector(selectCasinoFiltersSearchVisible);
  const searchValue = useTypedSelector(selectCasinoFiltersSearchValue);
  const handleChangeSearch = useCallback((value: string) => {
    dispatch(setCasinoFiltersSearchValue(value));
    if (value.length >= minSearchLength) {
      sendAnalyticsData(SEARCH_REQUEST, {
        cls: searchRef.current?.className,
        search_request: value
      });
    }
  }, [dispatch]);
  const handleClickSearch = useCallback(() => {
    dispatch(setCasinoFiltersSearchVisible(true));
    dispatch(setInitialCasinoFilters());
    sendAnalyticsData(SEARCH_CLICK, {
      cls: searchRef.current?.className
    });
  }, [dispatch]);
  const handleCloseSearch = useCallback(() => {
    dispatch(setCasinoFiltersSearchVisible(false));
    dispatch(setCasinoFiltersResultVisible(false));
  }, [dispatch]);
  const handleClearSearch = useCallback(() => {
    handleCloseSearch();
    sendAnalyticsData(SEARCH_CLEAR, {
      cls: searchRef.current?.className,
      search_request: searchValue
    });
  }, [handleCloseSearch, searchValue]);
  const handleBlur = useCallback(() => {
    if (searchRef.current?.value?.length === 0) {
      handleCloseSearch();
    }
  }, [handleCloseSearch]);
  const providerLabelText = t('provider', 'Provider');
  const genreLabelText = t('genre', 'Genre');
  const featureLabelText = t('feature', 'Feature');
  const showFiltersText = t('sidebar.filter', 'Filter');
  const filterButtonAnalytics = extendAnalyticsData(showFilters ? hideFiltersAnalytics : showFiltersAnalytics, {
    text: showFiltersText
  });
  useEffect(() => {
    handleCloseSearch();
    dispatch(resetCasinoFilters());
    return () => {
      dispatch(resetCasinoFilters());
      dispatch(reloadCasinoFilters());
      toggleShowFilters();
    };
  }, [dispatch, handleCloseSearch, pathname, query.category, toggleShowFilters]);
  return <div {...getTestProps(props)} className={cn(styles.root, {
    [styles.active]: showFilters
  })} ref={rootRef} data-sentry-component="CasinoFilters" data-sentry-source-file="CasinoFilters.tsx">
      <Button {...casinoPageObject.casinoFilters.filtersToggle.nodeProps} as="button" variant="text" fontWeight="bold" fontSize="lg" onClick={toggleShowFilters} className={cn(styles.filtersToggle, {
      [styles.active]: showFilters
    })} analytics={filterButtonAnalytics} data-sentry-element="Button" data-sentry-source-file="CasinoFilters.tsx">
        <FilterIcon className={styles.filterIcon} data-sentry-element="FilterIcon" data-sentry-source-file="CasinoFilters.tsx" />
        {showFiltersText}
        <ArrowIcon className={styles.arrowIcon} data-sentry-element="ArrowIcon" data-sentry-source-file="CasinoFilters.tsx" />
      </Button>

      {rootRef.current && <div className={cn(styles.dropdownsWrapper, {
      [styles.active]: showFilters
    })}>
          {renderFilters.includes('genre') && <Dropdown {...casinoPageObject.casinoFilters.filterSections('genre').nodeProps} {...genreProps} disabled={isLoading} title={genreLabelText} positionReference={rootRef.current} contentTestProps={casinoPageObject.casinoFilters.filterSections('genre').filterContent.nodeProps} analytics={extendAnalyticsData(genreAnalytics, {
        text: genreLabelText,
        count: renderFilters.length,
        position: renderFilters.indexOf('genre') + 1,
        filter_name: 'genre'
      })}>
              <CasinoFilterList {...casinoPageObject.casinoFilters.filterSections('genre').itemCheckbox.nodeProps} items={genre} type="genre" />
            </Dropdown>}
          {renderFilters.includes('feature') && <Dropdown {...casinoPageObject.casinoFilters.filterSections('feature').nodeProps} {...featureProps} disabled={isLoading} title={featureLabelText} positionReference={rootRef.current} contentTestProps={casinoPageObject.casinoFilters.filterSections('feature').filterContent.nodeProps} analytics={extendAnalyticsData(featureAnalytics, {
        text: featureLabelText,
        count: renderFilters.length,
        position: renderFilters.indexOf('feature') + 1,
        filter_name: 'feature'
      })}>
              <CasinoFilterList {...casinoPageObject.casinoFilters.filterSections('feature').itemCheckbox.nodeProps} items={feature} type="feature" />
            </Dropdown>}
          {renderFilters.includes('provider') && <Dropdown {...casinoPageObject.casinoFilters.filterSections('provider').nodeProps} {...providerProps} disabled={isLoading} title={providerLabelText} positionReference={rootRef.current} contentTestProps={casinoPageObject.casinoFilters.filterSections('provider').filterContent.nodeProps} analytics={extendAnalyticsData(providerAnalytics, {
        text: providerLabelText,
        count: renderFilters.length,
        position: renderFilters.indexOf('provider') + 1,
        filter_name: 'provider'
      })}>
              <CasinoFilterList {...casinoPageObject.casinoFilters.filterSections('provider').itemCheckbox.nodeProps} items={provider} type="provider" />
            </Dropdown>}
        </div>}
      <FormFieldSearch {...casinoPageObject.casinoFilters.searchField.nodeProps} ref={searchRef} value={searchValue} onBlur={handleBlur} onFocus={handleClickSearch} onClear={handleClearSearch} onChange={handleChangeSearch} placeholder="" className={cn(styles.searchField, {
      [styles.active]: showSearch
    })} classes={{
      componentWrapper: styles.input
    }} data-sentry-element="FormFieldSearch" data-sentry-source-file="CasinoFilters.tsx" />
    </div>;
};
export { CasinoFilters };