import type { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { noop } from '@mwl/core-lib';
import { Button } from '@/components/Button/Button';
import { CardGrid } from '@/components/CardGrid/CardGrid';
import { LoadableItemsWrapper } from '@/components/LoadableItemsWrapper/LoadableItemsWrapper';
import { GamesGridLayout } from '../GamesGridLayout/GamesGridLayout';
import { ProviderCard } from '../ProviderCard/ProviderCard';
import type { SectionProvidersProps } from './SectionProviders.types';
import styles from './SectionProviders.module.scss';
const SectionProviders: FC<SectionProvidersProps> = ({
  title,
  hasNext,
  providers,
  className,
  isInfinite,
  loadNext,
  ...props
}) => {
  const {
    t
  } = useTranslation('casino');
  const loadableProps = isInfinite ? ({
    as: 'infinite',
    loading: false,
    hasNextPage: !!hasNext,
    onLoadMore: loadNext ?? noop
  } as const) : ({
    as: 'block'
  } as const);
  return <GamesGridLayout title={title} className={className} data-sentry-element="GamesGridLayout" data-sentry-component="SectionProviders" data-sentry-source-file="SectionProviders.tsx">
      <LoadableItemsWrapper {...loadableProps} data-sentry-element="LoadableItemsWrapper" data-sentry-source-file="SectionProviders.tsx">
        <CardGrid hasNext={hasNext} isInfinite={isInfinite} {...props} data-sentry-element="CardGrid" data-sentry-source-file="SectionProviders.tsx">
          {providers.map(provider => <ProviderCard {...provider} width="auto" key={provider.id} />)}
        </CardGrid>
      </LoadableItemsWrapper>
      {loadNext && hasNext && !isInfinite && <div className={styles.buttonContainer}>
          <Button as="button" fontWeight="bold" rounded fontSize="lg" size="lg" variant="fulfilled" color="red" onClick={loadNext}>
            {t('more_providers', 'More provider')}
          </Button>
        </div>}
    </GamesGridLayout>;
};
export * from './SectionProviders.types';
export { SectionProviders };